import React, { createContext, useMemo, Suspense } from 'react'
import Svg, { Path, SvgProps } from 'react-native-svg'

import { styled, getTokenValue } from 'tamagui'

import { IconContext } from './context'

const StyledSVG = styled(Svg, {
  variants: {
    size: {
      '...size': (size) => {
        return {
          width: getTokenValue(size, 'size'),
          height: getTokenValue(size, 'size'),
          minWidth: getTokenValue(size, 'size'),
          minHeight: getTokenValue(size, 'size'),
        }
      },
    },
  },

  defaultVariants: {
    size: '$5',
  },
} as const)

export const IconWrapper = StyledSVG.styleable<{
  color: Parameters<typeof getTokenValue>[0]
}>(({ children, color, ...props }, ref) => {
  const contextValue = useMemo(() => {
    if (!color) {
      return { color: undefined }
    }

    const tokenColor = getTokenValue(color, 'color')

    return {
      color: tokenColor ? tokenColor : color,
    }
  }, [color])

  return (
    <StyledSVG
      viewBox='0 0 24 24'
      //   xmlns='http://www.w3.org/2000/svg'
      {...props}
      ref={ref}
    >
      <IconContext.Provider value={contextValue}>
        <Suspense>{children}</Suspense>
      </IconContext.Provider>
    </StyledSVG>
  )
})
