export const DOMAIN_NAME = (() => {
  if (process.env.GUILD_ENV === 'staging') {
    return 'staging.guild.host'
  }

  if (process.env.GUILD_ENV === 'production') {
    return 'guild.host'
  }

  return 'localhost:9010'
})()

export const HOST_NAME = (() => {
  if (process.env.NODE_ENV === 'production') {
    return `https://${DOMAIN_NAME}`
  }

  return `http://${DOMAIN_NAME}`
})()

export const IMAGEKIT_URL =
  process.env.GUILD_ENV === 'production'
    ? 'https://ik.imagekit.io/guild/prod'
    : 'https://ik.imagekit.io/guild/staging'
